.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.input-group label {
  width: 75px;
  flex-shrink: 0; /* 레이블의 크기가 내용에 맞춰지도록 함 */
}

.drbg-input-group label {
  width: 100px;
  flex-shrink: 0; /* 레이블의 크기가 내용에 맞춰지도록 함 */
}

.input-group input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
